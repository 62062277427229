import React from 'react';
import menuShowIcon from '../images/menu-show.svg';
import menuCloseIcon from '../images/menu-close.svg';
import logoFull from '../images/mevry-logo-full.svg';
import './header.scss';
import Socials from './socials';
import { CSSTransition } from 'react-transition-group';

const Header = ({ onClick, isMenuOpen }) => {
  return (
    <header className="header">
      <Socials className="header__socials" color="#fff" />
      <a href="/" className="header__logo-full">
        <img src={logoFull} alt="Mevry" className="header__logo-full" />
      </a>
      <button type="button" className="header__menu-btn" onClick={onClick}>
        <CSSTransition
          in={isMenuOpen}
          classNames="menu-btn--close"
          unmountOnExit
          timeout={{ enter: 200 }}
        >
          <img
            src={menuCloseIcon}
            width="4.8rem"
            height="4.8rem"
            alt="close menu"
          />
        </CSSTransition>
        <CSSTransition
          in={!isMenuOpen}
          classNames="menu-btn--show"
          unmountOnExit
          timeout={{ enter: 200 }}
        >
          <img src={menuShowIcon} alt="open menu" />
        </CSSTransition>
      </button>
    </header>
  );
};

export default Header;
