import React from 'react';

export default ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="25"
    viewBox="0 0 35 25"
  >
    <g
      id="Group_263"
      data-name="Group 263"
      transform="translate(812.697 433.86)"
    >
      <path
        id="Path_277"
        data-name="Path 277"
        d="M-797.845-412.823a17.833,17.833,0,0,1-2.765-.22,12.955,12.955,0,0,1-3.781-1.376l-1.236-.676,1.338-.44c1.462-.481,2.352-.779,3.454-1.246a4.6,4.6,0,0,1-2.362-2.737l-.312-.945.256.039a4.654,4.654,0,0,1-.582-.717,4.4,4.4,0,0,1-.746-2.616l.058-.825.494.191a4.623,4.623,0,0,1-.447-1.254,4.774,4.774,0,0,1,.508-3.213l.43-.789.576.691a10.592,10.592,0,0,0,6.859,3.862,4.1,4.1,0,0,1,.249-2.18,4.085,4.085,0,0,1,1.659-1.9,4.976,4.976,0,0,1,2.892-.71,4.617,4.617,0,0,1,2.84,1.171,9.587,9.587,0,0,0,1.039-.347c.228-.086.487-.184.81-.295l1.192-.411-.777,2.22c.051,0,.1-.008.159-.01l1.273-.058-.752,1.028c-.044.059-.054.076-.07.1-.06.091-.136.205-1.168,1.583a1.942,1.942,0,0,0-.364,1.266,11.981,11.981,0,0,1-.653,4.818,8.52,8.52,0,0,1-2.289,3.389,10.1,10.1,0,0,1-4.79,2.316,14.7,14.7,0,0,1-2.992.294Zm0,0"
        fill={fill}
      />
      <rect
        id="Rectangle_114"
        data-name="Rectangle 114"
        width="35"
        height="25"
        transform="translate(-812.697 -433.86)"
        fill="none"
      />
    </g>
  </svg>
);
