import React from 'react';
import PropTypes from 'prop-types';
import { instagramUrl, twitterUrl, facebookUrl } from '../data';
import InstagramIcon from '../images/social-instagram';
import TwitterIcon from '../images/social-twitter';
import FacebookIcon from '../images/social-facebook';

const Socials = ({ className, color }) => (
  <div className={className}>
    <a href={instagramUrl}>
      <InstagramIcon fill={color} />
    </a>
    <a href={twitterUrl}>
      <TwitterIcon fill={color} />
    </a>
    <a href={facebookUrl}>
      <FacebookIcon fill={color} />
    </a>
  </div>
);

Socials.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

export default Socials;
