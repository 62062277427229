/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Header from './header';
import Footer from './footer';
import Menu from './menu';
import './layout.scss';

const Layout = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggleMenu = () => setIsMenuOpen(isMenuOpen => !isMenuOpen);

  return (
    <>
      <Header isMenuOpen={isMenuOpen} onClick={handleToggleMenu} />
      <Menu
        style={{ display: isMenuOpen ? 'block' : 'none' }}
        onMenuItemClick={handleToggleMenu}
        isOpen={isMenuOpen}
      />
      <main className="main" style={{ display: isMenuOpen ? 'none' : 'block' }}>
        {children}
        <Footer />
      </main>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
