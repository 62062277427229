import React from 'react';
import PropTypes from 'prop-types';

import './menu.scss';
import { CSSTransition } from 'react-transition-group';

const Header = ({ isOpen, onMenuItemClick }) => (
  <CSSTransition
    in={isOpen}
    classNames="menu-transition"
    unmountOnExit
    timeout={300}
  >
    <div className="menu">
      <div>
        <a href="/#technology" onClick={onMenuItemClick}>
          <h2>Technology</h2>
        </a>
        <p>The power of AI to your business</p>
      </div>
      <div>
        <a href="/#audrey" onClick={onMenuItemClick}>
          <h2>Products</h2>
        </a>
        <p>Boost your sales with Mevry AI apps</p>
      </div>
      <div>
        <a href="/apps/privacy" onClick={onMenuItemClick}>
          <h2>Privacy Policy</h2>
        </a>
        <p>Privacy policy</p>
      </div>
      <div>
        <a href="/#contact" onClick={onMenuItemClick}>
          <h2>Contact</h2>
        </a>
        <p>How to contact us</p>
      </div>
    </div>
  </CSSTransition>
);

Header.propTypes = {
  isOpen: PropTypes.bool,
  onMenuItemClick: PropTypes.func,
};

export default Header;
