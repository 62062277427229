import React from 'react';

export default ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="25"
    viewBox="0 0 35 25"
  >
    <g
      id="Group_261"
      data-name="Group 261"
      transform="translate(854.697 433.86)"
    >
      <g id="Group_206" data-name="Group 206">
        <g id="Group_205" data-name="Group 205">
          <path
            id="Path_273"
            data-name="Path 273"
            d="M-833.277-430.11h-7.841a4.835,4.835,0,0,0-4.829,4.83v7.841a4.834,4.834,0,0,0,4.829,4.829h7.841a4.835,4.835,0,0,0,4.83-4.829v-7.841a4.836,4.836,0,0,0-4.83-4.83ZM-830-417.439a3.281,3.281,0,0,1-3.277,3.277h-7.841a3.281,3.281,0,0,1-3.277-3.277v-7.841a3.281,3.281,0,0,1,3.277-3.277h7.841A3.281,3.281,0,0,1-830-425.28Zm0,0"
            fill={fill}
          />
          <path
            id="Path_274"
            data-name="Path 274"
            d="M-837.2-425.868a4.514,4.514,0,0,0-4.51,4.508,4.515,4.515,0,0,0,4.51,4.51,4.515,4.515,0,0,0,4.509-4.51,4.514,4.514,0,0,0-4.509-4.508Zm0,7.465a2.96,2.96,0,0,1-2.957-2.957,2.96,2.96,0,0,1,2.957-2.956,2.961,2.961,0,0,1,2.957,2.956A2.96,2.96,0,0,1-837.2-418.4Zm0,0"
            fill={fill}
          />
          <path
            id="Path_275"
            data-name="Path 275"
            d="M-832.5-427.185a1.143,1.143,0,0,0-.8.333,1.142,1.142,0,0,0-.335.806,1.149,1.149,0,0,0,.335.8,1.147,1.147,0,0,0,.8.333,1.15,1.15,0,0,0,.805-.333,1.146,1.146,0,0,0,.334-.8,1.141,1.141,0,0,0-.334-.806,1.143,1.143,0,0,0-.805-.333Zm0,0"
            fill={fill}
          />
        </g>
      </g>
      <rect
        id="Rectangle_111"
        data-name="Rectangle 111"
        width="35"
        height="25"
        transform="translate(-854.697 -433.86)"
        fill="none"
      />
    </g>
  </svg>
);
