import React from 'react';

export default ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="25"
    viewBox="0 0 35 25"
  >
    <defs>
      <clipPath>
        <rect
          id="Rectangle_112"
          data-name="Rectangle 112"
          width="9.569"
          height="17.52"
          transform="translate(-757.982 -430.12)"
          fill="none"
        />
      </clipPath>
    </defs>
    <g
      id="Group_262"
      data-name="Group 262"
      transform="translate(770.697 433.86)"
    >
      <g id="Group_209" data-name="Group 209">
        <g id="Group_208" data-name="Group 208">
          <path
            id="Path_276"
            data-name="Path 276"
            d="M-748.866-430.138l-2.272,0a3.991,3.991,0,0,0-4.2,4.312v1.988h-2.285a.357.357,0,0,0-.357.357v2.88a.358.358,0,0,0,.357.357h2.285v7.268a.357.357,0,0,0,.357.357H-752a.358.358,0,0,0,.358-.357v-7.268h2.67a.357.357,0,0,0,.357-.357l0-2.88a.36.36,0,0,0-.1-.253.355.355,0,0,0-.253-.1h-2.671v-1.686c0-.809.193-1.221,1.247-1.221h1.531a.356.356,0,0,0,.357-.356v-2.675a.356.356,0,0,0-.356-.357Zm0,0"
            fill={fill}
          />
        </g>
      </g>
      <rect
        id="Rectangle_113"
        data-name="Rectangle 113"
        width="35"
        height="25"
        transform="translate(-770.697 -433.86)"
        fill="none"
      />
    </g>
  </svg>
);
