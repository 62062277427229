import React from 'react';
import Socials from './socials';
import { email } from '../data';
import './footer.scss';

const Footer = () => {
  return (
    <footer className="footer" id="contact">
      <a name="contact"></a>
      <h2 className="footer__heading">
        Let's stay<p>in touch</p>
      </h2>
      <div className="footer__contact-box">
        <div id="mc_embed_signup">
          <form
            action="https://mevry.us19.list-manage.com/subscribe/post?u=4622acb9b265f4d06b2838ef9&amp;id=f7b21bd3ea"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            noValidate
          >
            <input
              type="email"
              name="EMAIL"
              className="email"
              id="mce-EMAIL"
              placeholder="Your email"
            />
            <button type="submit" name="subscribe" id="mc-embedded-subscribe">
              Send
            </button>
          </form>
        </div>
        <div>
          <p>
            <a href="/#technology">Technology</a>
          </p>
          <p>
            <a href="#audrey">Products</a>
          </p>
          <p>
            <a href="/apps/privacy">Privacy </a>
          </p>
        </div>
        <div>
          <p>
            <a href={`mailto:${email}`}>{email}</a>
          </p>
          <p>Follow us</p>
          <Socials className="socials" color="#F7931E" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
